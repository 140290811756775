.data-table {
  th {
    position: sticky !important;
    top: 112px;
    z-index: 99;
    align-self: flex-start;
  }

  .ant-table-pagination.ant-pagination {
    position: sticky;
    bottom: 0;
    display: block;
    margin: 0;
    padding: 16px;
    background: #fff;
    float: none;
  }

  .operation-bar {
    position: sticky;
    top: 64px;
    background: #fff;
    z-index: 1;
  }
}
