.page-menu {

  background: #14adbf;
  margin: 0;
  padding: 0;
  border: 0;

  .ant-menu-submenu,
  .ant-menu-item {
    border-bottom: none;
    color: #fff;
  }

  .ant-menu-submenu-selected,
  .ant-menu-submenu-active,
  .ant-menu-submenu:hover,
  .ant-menu-item-selected,
  .ant-menu-item-active,
  .ant-menu-item:hover {
    background: #fff;
    color: #14adbf;
    border-bottom: none;

    .ant-menu-submenu-title,
    .ant-menu-submenu,
    .anticon,
    .anticon-menu,
    .ant-menu {
      color: #14adbf;
    }
  }

  .ant-menu-item-selected,
  .ant-menu-item-active {
    background: #fff;
    color: #14adbf;
  }

  .ant-menu {
    color: #fff;
  }
  .anticon-menu {
    color: #fff;
  }
}