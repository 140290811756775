.data-card {
  .ant-pagination {
    position: sticky;
    bottom: 0;
    display: block;
    margin: 0;
    padding: 16px;
    background: #fff;
    float: none;
  }

  .ant-card-body {
    padding: 16px;
  }

  .operation-bar {
    position: sticky;
    top: 64px;
    background: #fff;
    z-index: 1;
  }
}
