#print-area {
  // A4 aspect ratio
  width: 240mm;
  min-height: 309mm;
}

#print-area-wrap {
  width: 250mm;
}
@media print {
  body * {
    visibility: hidden;
  }
  #print-area {
    *{
      visibility: visible;
    }
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    min-height: auto;
  }

  #print-area-wrap {
    width: 100%;
  }
}
