@import '~antd/dist/antd.css';

.user {
  cursor: pointer;
  display:inline-block;
  color: #fff;
  .avatar {
    border: 1px solid #fff;
    color: #14adbf;
    background: #fff;
  }
}
.user:hover {
  .avatar {
    background: #fff;
    transition: all 1s;
    color: #14adbf;
  }
  color: #fff;
  transition: all 0.4s;
}