.title-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 0 24px;
  height: 100px;
  background: #14adbf;
  z-index: 100;
  &.floating {
    height: 64px;
    h1 {
      font-size: 1.45em;
    }
  }
  h1 {
    color: #fff;
    margin: 0;
  }
}