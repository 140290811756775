.loading-bar {
  position: relative;
  width: 100%;
  height: 4px;
  background: #e8e8e8;
  overflow: hidden;

  &:after {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 200%;
    background: #14adbf;
    animation: linear kf-loading-a 1.3s infinite;
    animation-delay: 1.5s;
    @keyframes kf-loading-a {
      0% {
        left: -100%
      }
      75% {
        left: 90%;
      }
      100% {
        left: 100%;
      }
    }

  }

  &:before {
    content: ' ';
    position: absolute;

    width: 100%;
    height: 100%;
    left: 0;
    background: #14adbf;
    animation-fill-mode: forwards;
    animation: linear kf-loading-b 1.3s infinite;

    @keyframes kf-loading-b {
      0% {
        left: -100%
      }
      100% {
        left: 100%;
      }
    }

  }

}