.HomeButton {
    position: fixed;
    height: 70px;
    width: 70px;
    line-height: 70px;
    background: #14adbf;
    bottom: 20px;
    right: 20px;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    font-size: 36px;
    cursor: pointer;
    box-shadow: 0px 17px 10px -10px rgba(0, 0, 0, 0.4);
    z-index: 9999;
}